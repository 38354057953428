<script setup>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import constants from '@/constants'
const { locale } = useI18n()
// import { activateDarkMode, deactivateDarkMode } from '@/assets/js/dark-mode'

const store = useStore()
// state
const isRTL = computed(() => store.state.isRTL)
// const isNavFixed = computed(() => store.state.isNavFixed)
// const sidebarType = computed(() => store.state.sidebarType)
const toggleConfigurator = () => store.commit('toggleConfigurator')
const base = reactive({
  selectedLanguage: 'en-US',
  languages: constants.LANGUAGES
})

const changeLanguage = lang => {
  locale.value = lang
}

// mutations
// const navbarFixed = () => store.commit('navbarFixed')
// const setSidebarType = type => store.commit('sidebarType', type)

// const sidebarColor = (color = 'success') => {
//   document.querySelector('#sidenav-main').setAttribute('data-color', color)
// }

// const darkMode = () => {
//   if (store.state.darkMode) {
//     store.state.darkMode = false
//     setSidebarType('bg-white')
//     deactivateDarkMode()
//     return
//   } else {
//     store.state.darkMode = true
//     setSidebarType('bg-default')
//     activateDarkMode()
//   }
// }
</script>

<template>
  <div class="fixed-plugin">
    <!-- Floating configuration -->
    <!-- <a class="px-3 py-2 fixed-plugin-button text-dark position-fixed" @click="toggleConfigurator">
      <i class="py-2 fa fa-cog"></i>
    </a> -->
    <div class="shadow-lg card">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="" :class="isRTL ? 'float-end' : 'float-start'">
          <h5 class="mt-3 mb-0">Configurations</h5>
          <!-- <p>See our dashboard options.</p> -->
        </div>
        <div class="mt-4" :class="isRTL ? 'float-start' : 'float-end'" @click="toggleConfigurator">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="pt-0 card-body pt-sm-3">
        <!-- Sidebar Backgrounds -->
        <div>
          <h6 class="mb-0">Select Language</h6>
        </div>
        <el-select
          v-model="base.selectedLanguage"
          value-key="name"
          :placeholder="$t('common.select')"
          size="large"
          class="mt10"
          filterable
          @change="changeLanguage"
        >
          <el-option
            v-for="item in base.languages"
            :key="item.name"
            :label="item.displayName"
            :value="item.name"
          />
        </el-select>
        <!-- <a href="#" class="switch-trigger background-color">
          <div class="my-2 badge-colors" :class="isRTL ? 'text-end' : ' text-start'">
            <span
              class="badge filter bg-gradient-primary active"
              data-color="primary"
              @click="sidebarColor('primary')"
            ></span>
            <span
              class="badge filter bg-gradient-dark"
              data-color="dark"
              @click="sidebarColor('dark')"
            ></span>
            <span
              class="badge filter bg-gradient-info"
              data-color="info"
              @click="sidebarColor('info')"
            ></span>
            <span
              class="badge filter bg-gradient-success"
              data-color="success"
              @click="sidebarColor('success')"
            ></span>
            <span
              class="badge filter bg-gradient-warning"
              data-color="warning"
              @click="sidebarColor('warning')"
            ></span>
            <span
              class="badge filter bg-gradient-danger"
              data-color="danger"
              @click="sidebarColor('danger')"
            ></span>
          </div>
        </a> -->
        <!-- Sidenav Type -->
        <!-- <div class="mt-3">
          <h6 class="mb-0">Sidenav Type</h6>
          <p class="text-sm">Choose between 2 different sidenav types.</p>
        </div>
        <div class="d-flex gap-2">
          <button
            id="btn-white"
            class="btn w-100 px-3 mb-2"
            :class="sidebarType === 'bg-white' ? 'bg-gradient-success' : 'btn-outline-success'"
            @click="setSidebarType('bg-white')"
          >
            White
          </button>
          <button
            id="btn-dark"
            class="btn w-100 px-3 mb-2"
            :class="sidebarType === 'bg-default' ? 'bg-gradient-success' : 'btn-outline-success'"
            @click="setSidebarType('bg-default')"
          >
            Dark
          </button>
        </div>
        <p class="mt-2 text-sm d-xl-none d-block">
          You can change the sidenav type just on desktop view.
        </p> -->
        <!-- Navbar Fixed -->
        <!-- Navbar Fixed -->
        <!-- <div class="mt-3 d-flex">
          <h6 class="mb-0">Navbar Fixed</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              id="navbarFixed"
              class="mt-1 form-check-input"
              :class="isRTL ? 'float-end  me-auto' : ' ms-auto'"
              type="checkbox"
              :checked="isNavFixed"
              @click="navbarFixed"
            />
          </div>
        </div>

        <hr class="horizontal dark my-4" />
        <div class="mt-2 mb-5 d-flex">
          <h6 class="mb-0" :class="isRTL ? 'ms-2' : ''">Light / Dark</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              class="form-check-input mt-1 ms-auto"
              type="checkbox"
              :checked="store.state.darkMode"
              @click="darkMode"
            />
          </div>
        </div>
        <a
          class="btn bg-gradient-dark w-100"
          href="https://www.creative-tim.com/product/vue-argon-dashboard"
        >
          Free Download
        </a>
        <a
          class="btn btn-outline-dark w-100"
          href="https://www.creative-tim.com/learning-lab/vue/overview/argon-dashboard/"
        >
          View documentation
        </a>
        <div class="text-center w-100">
          <a
            class="github-button"
            href="https://github.com/creativetimofficial/vue-argon-dashboard"
            data-icon="octicon-star"
            data-size="large"
            data-show-count="true"
            aria-label="Star creativetimofficial/vue-argon-dashboard on GitHub"
          >
            Star
          </a>
          <h6 class="mt-3">Thank you for sharing!</h6>
          <a
            href="https://twitter.com/intent/tweet?text=Check%20Vue%20Argon%20Dashboard%202%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%vuejs3&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%vue-argon-dashboard"
            class="mb-0 btn btn-dark me-2"
            target="_blank"
          >
            <i class="fab fa-twitter me-1" aria-hidden="true"></i>
            Tweet
          </a>
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/vue-argon-dashboard"
            class="mb-0 btn btn-dark me-2"
            target="_blank"
          >
            <i class="fab fa-facebook-square me-1" aria-hidden="true"></i>
            Share
          </a>
        </div> -->
      </div>
    </div>
  </div>
</template>
