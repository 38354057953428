<template>
  <div v-loading.fullscreen.lock="base.loading" class="card">
    <div class="card-header px-2 pb-0">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="base.search.text"
            :placeholder="$t('common.inputPlaceholder')"
            clearable
            class="input-with-select"
            @keyup.enter="onSearch"
          >
            <template #prepend>
              <el-select
                v-model="base.search.key"
                :placeholder="$t('common.select')"
                style="width: 150px"
              >
                <el-option :label="$t('invoiceNumber')" value="invoiceNumber" />
              </el-select>
            </template>
            <template #append>
              <el-button :icon="Search" @click="onSearch" />
            </template>
          </el-input>
        </el-col>
        <el-col :span="12" class="pr20">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddInvoice"
          >
            {{ $t('invoice') }}
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-table
        ref="tableRef"
        class="fnbinvoicestable-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :data="base.data.records"
        :stripe="true"
        :scrollbar-always-on="true"
        style="width: 100%"
      >
        <el-table-column prop="invoiceNumber" :label="$t('invoiceNumber')" />
        <el-table-column prop="totalPrice" :label="$t('totalPrice')" />
        <el-table-column label="">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleEditClick(scope.row)">
              {{ $t('edit') }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleGenerateInvoice(scope.row)">
              {{ $t('reservations.generateInvoice') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-pagination
        :current-page="base.data.cursor.currentPage"
        size="small"
        class="m-t-10 mb2 ml10"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="base.data.cursor.perPage"
        :page-sizes="base.pageSizes"
        :total="parseInt(base.data.cursor.totalRecords)"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-model="base.invoiceFormVisible"
      :title="$t('fnbInvoices.title')"
      destroy-on-close
      align-center
      fullscreen
    >
      <el-form label-position="top">
        <el-row class="mt-3" :gutter="20">
          <el-col :span="8" class="tl">
            <el-form-item :label="$t('reservations.roomNumber')">
              <el-select
                v-model="base.invoiceForm.guestDetails.roomInvoiceId"
                clearable
                filterable
                :placeholder="$t('reservations.selectRoom')"
                @change="handleReservationChange"
              >
                <el-option
                  v-for="item in base.reservations"
                  :key="item._id"
                  value-key="_id"
                  :label="`${item.room.roomNumber} - ${item.guestDetails.name}`"
                  :value="item._id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('title')" prop="guestDetails.title">
              <el-select
                v-model="base.invoiceForm.guestDetails.title"
                :disabled="!!base.invoiceForm.guestDetails.roomInvoiceId"
                :placeholder="$t('common.select')"
              >
                <el-option
                  v-for="item in base.titleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('reservations.guestName')" prop="guestDetails.name">
              <el-input
                v-model="base.invoiceForm.guestDetails.name"
                :disabled="!!base.invoiceForm.guestDetails.roomInvoiceId"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-dialog
        v-model="base.menuItemDialogVisible"
        width="50%"
        :title="$t('fnbInvoices.menuItemTitle')"
        append-to-body
      >
        <el-form
          ref="menuItemRef"
          label-width="auto"
          :model="base.menuItemForm"
          :rules="base.menuItemRules"
        >
          <el-row class="mt10 mb-0" :gutter="20">
            <el-col :span="24">
              <el-form-item :label="$t('fnbInvoices.category')" prop="category">
                <el-select
                  v-model="base.menuItemForm.category"
                  :placeholder="$t('fnbInvoices.selectCategory')"
                  size="large"
                  @change="handleCategoryChange"
                >
                  <el-option
                    v-for="item in base.categories"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('fnbInvoices.menuItem')" prop="name">
                <el-select
                  v-model="base.menuItemForm.name"
                  :placeholder="$t('fnbInvoices.selectMenuItem')"
                  size="large"
                  value-key="name"
                  @change="handleMenuItemChange"
                >
                  <el-option
                    v-for="item in base.selectedMenuItems"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('fnbInvoices.quantity')" prop="quantity">
                <el-input-number
                  v-model="base.menuItemForm.quantity"
                  :min="1"
                  class="w-100"
                  controls-position="right"
                  size="large"
                  @change="handleQuantityChange"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('price')">
                <el-input-number
                  v-model="base.menuItemForm.price"
                  disabled
                  class="w-100"
                  size="large"
                  :controls="false"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24" class="justify-content-right">
              <el-button @click="base.menuItemDialogVisible = false">
                {{ $t('reservations.cancel') }}
              </el-button>
              <el-button type="primary" @click="handleAddMenuItemClick">{{ $t('add') }}</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
      <el-row>
        <el-col :span="12">
          <h5>{{ $t('fnbInvoices.menuItems') }}</h5>
        </el-col>
        <el-col :span="12">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="base.menuItemDialogVisible = true"
          >
            {{ $t('fnbInvoices.item') }}
          </el-button>
        </el-col>
      </el-row>
      <hr class="horizontal dark" />
      <el-table
        ref="tableRef"
        class="items-table-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :data="base.invoiceForm.items"
        style="width: 100%"
      >
        <el-table-column prop="category" :label="$t('fnbInvoices.category')" />
        <el-table-column prop="name" :label="$t('fnbInvoices.menuItem')" />
        <el-table-column prop="itemPrice" :label="$t('defaultPrice')" />
        <el-table-column prop="quantity" :label="$t('fnbInvoices.quantity')" />
        <el-table-column prop="price" :label="$t('totalPrice')" />
        <el-table-column label="" min-width="75">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleMenuItemEditClick(scope.row)">
              {{ $t('edit') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-row :gutter="20" class="dialog-footer">
          <el-col :span="8" class="tl">
            <el-form>
              <el-form-item :label="$t('totalPrice')">
                <el-input-number
                  v-model="base.invoiceForm.totalPrice"
                  disabled
                  class="w-100"
                  :controls="false"
                />
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="16" class="justify-content-right">
            <el-button @click="base.invoiceFormVisible = false">
              {{ $t('reservations.cancel') }}
            </el-button>
            <el-button type="primary" @click="handleSave">{{ $t('reservations.save') }}</el-button>
          </el-col>
        </el-row>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Search, Plus } from '@element-plus/icons-vue'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { ElNotification } from 'element-plus'
import moment from 'moment'

import constants from '@/constants'
import FNBInvoiceSvc from '@/services/fnbInvoice'
import MenuItemSvc from '@/services/menuItem'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import InvoiceSvc from '@/services/invoice'
import { useI18n } from 'vue-i18n'

export default {
  name: 'FNBInvoicesTable',
  setup() {
    const { t } = useI18n()
    const menuItemRef = ref(null)
    const base = reactive({
      loading: false,
      invoiceFormVisible: false,
      menuItemDialogVisible: false,
      search: {
        text: '',
        key: 'invoiceNumber'
      },
      menuItemForm: {},
      invoiceForm: {},
      data: {
        records: [],
        cursor: { currentPage: 0, perPage: 0, totalRecords: 0 }
      },
      pageSizes: constants.DEFAULT_PAGE_SIZES,
      menuItems: [],
      selectedMenuItems: [],
      menuItemRules: {
        category: [{ required: true, message: ' ', trigger: 'blur' }],
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        quantity: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      reservations: [],
      titleOptions: constants.TITLE_OPTIONS
    })

    const local = {
      currentQuery: undefined,
      sort: {},
      fields: undefined
    }

    const onSearch = () => {
      let q = local.currentQuery
      if (base.search.text) {
        q = {}
        local.currentPage = 1
        // if (searchKeyType === 'date') {
        //   q[searchKey] = {
        //     $gte: QueryBuilder.getDate(searchText, 1),
        //     $lt: QueryBuilder.getDate(searchText, 2)
        //   }
        // } else {
        //   q[searchKey] = { $regex: '.*' + searchText + '.*', $options: 'i' }
        // }
        q[base.search.key] = {
          $regex: '.*' + base.search.text + '.*',
          $options: 'i'
        }
        if (base.search.key === 'invoiceNumber') {
          const value = base.search.text.split('-')
          const searchText = value?.[1] ?? base.search.text
          q[base.search.key] = parseInt(searchText)
        }
      } else {
        q = {}
      }
      if (!_.isEqual(q, local.currentQuery)) {
        local.currentQuery = q
        fetchFNBInvoices(
          local.currentQuery,
          local.fields,
          base.data.cursor.currentPage,
          base.data.cursor.perPage,
          local.sort
        )
      }
    }

    onMounted(() => {
      fetchInvoices()
      fetchFNBInvoices()
      fetchMenuItems()
    })

    const fetchInvoices = () => {
      const options = AuthSvc.getOptions()
      const fromDate = moment().startOf('day')._d
      const toDate = moment().endOf('day')._d
      const query = {
        checkIn: {
          $gte: fromDate,
          $lt: toDate
        },
        isCheckedOut: false
      }
      InvoiceSvc.find(options, query, undefined, 1, 1000)
        .then(response => {
          base.reservations = response.data.records
        })
        .catch(error => {
          console.log('FNBInvoicesTable fetchInvoices error => ', error)
        })
    }

    const handleReservationChange = val => {
      const reservation = base.reservations.find(r => r._id === val)
      if (reservation) {
        base.invoiceForm.guestDetails.roomNumber = reservation.room.roomNumber
        base.invoiceForm.guestDetails.name = reservation.guestDetails.name
        base.invoiceForm.guestDetails.title = reservation.guestDetails.title
      } else {
        base.invoiceForm.guestDetails = {}
      }
    }

    const fetchFNBInvoices = (query, projection, page, limit, sort) => {
      const options = AuthSvc.getOptions()
      FNBInvoiceSvc.find(options, query, projection, page, limit, sort)
        .then(response => {
          base.data = response.data
        })
        .catch(error => {
          console.log('FNBInvoicesTable fetch error => ', error)
          common.showErrors(error)
        })
    }

    const handleSave = () => {
      const options = AuthSvc.getOptions()
      if (
        _.isEmpty(base.invoiceForm.guestDetails.title) ||
        _.isEmpty(base.invoiceForm.guestDetails.name)
      ) {
        ElNotification({
          title: 'Error',
          message: 'Kindly enter the guests name and title before proceeding.',
          type: 'error'
        })
      } else if (base.invoiceForm?.items?.length === 0) {
        ElNotification({
          title: 'Error',
          message: 'Please add atleast one menu item',
          type: 'error'
        })
      } else {
        FNBInvoiceSvc.save(options, base.invoiceForm)
          .then(() => {
            base.invoiceFormVisible = false
            fetchFNBInvoices()
          })
          .catch(error => {
            console.log('FNBInvoicesTable handleSave error => ', error)
            common.showErrors(error)
          })
      }
    }

    const fetchMenuItems = () => {
      const options = AuthSvc.getOptions()
      MenuItemSvc.find(options, undefined, undefined, 1, 1000)
        .then(response => {
          base.menuItems = response.data.records
          base.categories = _.uniqBy(base.menuItems, 'category').map(item => item.category)
          base.selectedMenuItems = _.clone(base.menuItems)
        })
        .catch(error => {
          console.log('FNBInvoicesTable fetchMenuItems error => ', error)
          common.showErrors(error)
        })
    }

    const handleCreateNewMenuItem = () => {
      return {
        id: '',
        category: '',
        name: '',
        quantity: 1,
        price: 0,
        itemPrice: 0,
        taxPercentage: 0
      }
    }

    const handleCreateNewInvoice = () => {
      return {
        guestDetails: {
          roomInvoiceId: '',
          roomNumber: ''
        },
        items: [],
        totalPrice: 0
      }
    }

    const handleAddInvoice = () => {
      base.menuItemForm = handleCreateNewMenuItem()
      base.invoiceForm = handleCreateNewInvoice()
      base.invoiceFormVisible = true
    }

    const handleEditClick = fnbInvoice => {
      const options = AuthSvc.getOptions()
      FNBInvoiceSvc.findById(options, fnbInvoice._id)
        .then(response => {
          const invoice = response.data
          if (!invoice.guestDetails) {
            invoice.guestDetails = {}
          }
          base.invoiceForm = invoice
          base.invoiceFormVisible = true
        })
        .catch(error => {
          console.log('FNBInvoicesTable handleEditClick error => ', error)
          common.showErrors(error)
        })
    }

    const handleCategoryChange = () => {
      if (base.menuItemForm.category) {
        base.selectedMenuItems = base.menuItems.filter(
          item => item.category === base.menuItemForm.category
        )
      } else {
        base.selectedMenuItems = _.clone(base.menuItems)
      }
    }

    const handleQuantityChange = () => {
      base.menuItemForm.price = base.menuItemForm.itemPrice * base.menuItemForm.quantity
    }

    const handleMenuItemChange = val => {
      const menuItem = base.selectedMenuItems.find(item => item.name === val)
      base.menuItemForm.itemPrice = _.clone(menuItem.price.default)
      base.menuItemForm.taxPercentage = _.clone(menuItem.taxPercentage)
      handleQuantityChange()
    }

    const handleAddMenuItemClick = () => {
      menuItemRef.value.validate(valid => {
        if (valid) {
          if (base.menuItemForm.id) {
            const menuItemIndex = base.invoiceForm.items.findIndex(
              item => item.id === base.menuItemForm.id
            )
            if (menuItemIndex !== -1) {
              base.invoiceForm.items.splice(menuItemIndex, 1, _.clone(base.menuItemForm))
            }
          } else {
            base.menuItemForm.id = uuidv4()
            base.invoiceForm.items.push(_.clone(base.menuItemForm))
          }
          base.menuItemDialogVisible = false
          base.menuItemForm = handleCreateNewMenuItem()
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const handleCurrentPageChange = val => {
      base.data.cursor.currentPage = val
      fetchFNBInvoices(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    const handlePageSizeChange = val => {
      base.data.cursor.perPage = val
      fetchFNBInvoices(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    const handleGenerateInvoice = row => {
      base.loading = true
      const options = AuthSvc.getOptions()
      FNBInvoiceSvc.generate(options, row._id)
        .then(res => {
          base.loading = false
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob) // Create a temporary URL for the file
          const newWindow = window.open(url, '_blank') // Open the file in a new tab or window

          if (newWindow) {
            newWindow.onload = () => {
              newWindow.print() // Trigger the print dialog once the file is loaded
            }
          }

          // Revoke the Object URL to release memory
          window.URL.revokeObjectURL(url)
          ElNotification({
            type: 'success',
            message: 'Invoice generated successfully!'
          })
        })
        .catch(error => {
          base.loading = false
          console.log('DNBInvoicesTable handleGenerateInvoice error => ', error)
          common.showErrors(error)
        })
    }

    const handleMenuItemEditClick = row => {
      console.log('==>', row)
      console.log('base.invoiceForm.items', base.invoiceForm.items)
      const menuItem = _.cloneDeep(base.invoiceForm.items.find(item => item.id === row.id))
      if (menuItem) {
        base.menuItemForm = menuItem
      }
    }

    return {
      t,
      base,
      menuItemRef,
      Search,
      Plus,
      handleEditClick,
      handleAddInvoice,
      handleSave,
      handleCategoryChange,
      handleMenuItemChange,
      handleQuantityChange,
      handleAddMenuItemClick,
      handleCurrentPageChange,
      handlePageSizeChange,
      onSearch,
      handleGenerateInvoice,
      handleMenuItemEditClick,
      handleReservationChange
    }
  }
}
</script>

<style>
.fnbinvoicestable-max-height {
  height: calc(100vh - 205px) !important;
}
.items-table-max-height {
  min-height: calc(100vh - 286px);
  max-height: calc(100vh - 286px);
}
.justify-content-right {
  justify-content: flex-end;
  display: flex !important;
}
</style>
