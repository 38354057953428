<template>
  <div v-loading.fullscreen.lock="base.loading" class="card menu-items">
    <el-tabs v-model="base.activeTab" class="menu-items-tab" addable tab-position="left">
      <template #add-icon>
        <el-button
          class="menu-items-category-button text-uppercase"
          type="danger"
          size="small"
          :icon="Plus"
          @click="handleAddCategory"
        >
          Category
        </el-button>
      </template>
      <el-tab-pane
        v-for="item in base.categories"
        :key="item._id"
        :label="item.category"
        :name="item.category"
        class="category-tab"
      >
        <menu-item-tab-content
          v-if="base.activeTab === item.category"
          :category="item.category"
        ></menu-item-tab-content>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      v-model="base.categoryFormVisible"
      title="Add Category"
      width="50%"
      destroy-on-close
      align-center
    >
      <el-form
        ref="categoryFromRef"
        class="mt20"
        :model="base.categoryForm"
        :rules="base.categoryRules"
      >
        <el-col>
          <el-form-item :label="$t('fnbInvoices.category')" prop="category">
            <el-input v-model="base.categoryForm.category" size="large" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.categoryFormVisible = false">
            {{ $t('reservations.cancel') }}
          </el-button>
          <el-button type="primary" @click="handleSave">Confirm</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import MenuItemSvc from '@/services/menuItem'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import _ from 'lodash'
import { useI18n } from 'vue-i18n'
import MenuItemTabContent from './MenuItemTabContent.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'MenuItemsTable',
  components: {
    MenuItemTabContent
  },
  setup() {
    const categoryFromRef = ref(null)
    const { t } = useI18n()
    const base = reactive({
      loading: false,
      categories: [],
      categoryFormVisible: false,
      categoryForm: {},
      categoryRules: [],
      activeTab: ''
    })

    onMounted(() => {
      fetchMenuItems()
    })

    const fetchMenuItems = () => {
      base.loading = true
      const options = AuthSvc.getOptions()
      const query = {}
      MenuItemSvc.find(options, query, 'category', 1, 10000)
        .then(response => {
          const records = response.data.records
          base.categories = _.uniqBy(records, 'category')
          base.activeTab = base.categories[0]?.category || ''
          base.loading = false
        })
        .catch(error => {
          base.loading = false
          console.log('MenuItemsTable fetchMenuItems error => ', error)
          common.showErrors(error)
        })
    }

    const handleSave = () => {
      categoryFromRef.value.validate(valid => {
        if (valid) {
          base.categories.push({ _id: uuidv4(), category: base.categoryForm.category })
          base.categoryFormVisible = false
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const handleAddCategory = () => {
      base.categoryForm.category = ''
      base.categoryFormVisible = true
    }

    return {
      t,
      base,
      Plus,
      handleAddCategory,
      handleSave,
      categoryFromRef
    }
  }
}
</script>

<style>
.menu-items {
  padding: 10px;
}
.menu-items-tab {
  height: 100%;
}
.menu-items-category-button {
  padding: 12px !important;
}
</style>
