<template>
  <el-row class="pricing-hero w-100">
    <el-col class="text-center">
      <h3 class="pricing-heading">Pricing</h3>
    </el-col>
    <el-row class="w-100" :gutter="20">
      <el-col :span="8">
        <el-card class="w-100 plan-card" shadow="hover">
          <h4 class="plan-name">ZATA Restaurants</h4>
          <h5 class="price">₹ 499/month</h5>
          <el-row>
            <el-icon color="#00a3ff" class="plan-feature-icon"><SelectIcon /></el-icon>
            FNB
          </el-row>
          <el-row>
            <el-icon color="#00a3ff" class="plan-feature-icon"><SelectIcon /></el-icon>
            Staff Details
          </el-row>
          <el-row>
            <el-icon color="#00a3ff" class="plan-feature-icon"><SelectIcon /></el-icon>
            Real Time Dashboard
          </el-row>
          <el-row>
            <el-icon color="red" class="plan-feature-icon"><CloseBold /></el-icon>
            Invoice
          </el-row>
          <div class="plan-btn">
            <a href="#" class="landing-btn landing-btn-primary">
              {{ t('landingPage.startFreeTrial') }}
            </a>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="w-100 plan-card" shadow="hover">
          <h4 class="plan-name">ZATA Basic</h4>
          <h5 class="price">₹ 999/month</h5>
          <el-row>
            <el-icon color="red" class="plan-feature-icon"><CloseBold /></el-icon>
            FNB
          </el-row>
          <el-row>
            <el-icon color="#00a3ff" class="plan-feature-icon"><SelectIcon /></el-icon>
            Staff Details
          </el-row>
          <el-row>
            <el-icon color="#00a3ff" class="plan-feature-icon"><SelectIcon /></el-icon>
            Real Time Dashboard
          </el-row>
          <el-row>
            <el-icon color="#00a3ff" class="plan-feature-icon"><SelectIcon /></el-icon>
            Invoice
          </el-row>
          <div class="plan-btn">
            <a href="#" class="landing-btn landing-btn-primary">
              {{ t('landingPage.startFreeTrial') }}
            </a>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="w-100 plan-card" shadow="hover">
          <h4 class="plan-name">ZATA Pro</h4>
          <h5 class="price">₹ 1449/month</h5>
          <el-row>
            <el-icon color="#00a3ff" class="plan-feature-icon"><SelectIcon /></el-icon>
            FNB
          </el-row>
          <el-row>
            <el-icon color="#00a3ff" class="plan-feature-icon"><SelectIcon /></el-icon>
            Staff Details
          </el-row>
          <el-row>
            <el-icon color="#00a3ff" class="plan-feature-icon"><SelectIcon /></el-icon>
            Real Time Dashboard
          </el-row>
          <el-row>
            <el-icon color="#00a3ff" class="plan-feature-icon"><SelectIcon /></el-icon>
            Invoice
          </el-row>
          <div class="plan-btn">
            <a href="#" class="landing-btn landing-btn-primary">
              {{ t('landingPage.startFreeTrial') }}
            </a>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { CloseBold, Select } from '@element-plus/icons-vue'
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Pricing',
  components: {
    CloseBold,
    SelectIcon: Select
  },
  setup() {
    const { t } = useI18n()
    const base = reactive({})

    return {
      t,
      base
    }
  }
}
</script>

<style>
.pricing-hero {
  padding: 5rem 0rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.plan-card {
  padding: 40px;
}
.pricing-heading {
  margin-bottom: 20px;
}
.price {
  text-align: center;
  margin-bottom: 20px;
}
.plan-name {
  text-align: center;
  margin-bottom: 15px;
}
.plan-btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.plan-feature-icon {
  margin-top: 6px;
  margin-right: 10px;
  /* color: #00a3ff; */
}
</style>
