import { createI18n } from 'vue-i18n'

// Language definitions
import enUS from './enUS.json'
import hi from './hi.json'
import tamil from './tamil.json'
import telugu from './telugu.json'
import kannada from './kannada.json'
import malayalam from './malayalam.json'
import gujrati from './gujrati.json'
import marathi from './marathi.json'
import bengali from './bengali.json'
import urdu from './urdu.json'
import odia from './odia.json'
import punjabi from './punjabi.json'
import sanskrit from './sanskrit.json'

const messages = {
  'en-US': enUS.messages,
  hi: hi.messages,
  tamil: tamil.messages,
  telugu: telugu.messages,
  kannada: kannada.messages,
  malayalam: malayalam.messages,
  gujrati: gujrati.messages,
  marathi: marathi.messages,
  bengali: bengali.messages,
  urdu: urdu.messages,
  odia: odia.messages,
  punjabi: punjabi.messages,
  sanskrit: sanskrit.messages
}

const datetimeFormats = {
  'en-US': enUS.dateFormat,
  hi: hi.dateFormat,
  tamil: tamil.dateFormat,
  telugu: telugu.dateFormat,
  kannada: kannada.dateFormat,
  malayalam: malayalam.dateFormat,
  gujrati: gujrati.dateFormat,
  marathi: marathi.dateFormat,
  bengali: bengali.dateFormat,
  urdu: urdu.dateFormat,
  odia: odia.dateFormat,
  punjabi: punjabi.dateFormat,
  sanskrit: sanskrit.dateFormat
}

const numberFormats = {
  'en-US': enUS.numberFormat,
  hi: hi.numberFormat,
  tamil: tamil.numberFormat,
  telugu: telugu.numberFormat,
  kannada: kannada.numberFormat,
  malayalam: malayalam.numberFormat,
  gujrati: gujrati.numberFormat,
  marathi: marathi.numberFormat,
  bengali: bengali.numberFormat,
  urdu: urdu.numberFormat,
  odia: odia.numberFormat,
  punjabi: punjabi.numberFormat,
  sanskrit: sanskrit.numberFormat
}

// Language Config
const i18n = createI18n({
  locale: 'en-US', // set locale
  silentTranslationWarn: true, // to avoid empty key warnings
  silentFallbackWarn: true, // to avoid fallback warnings
  allowComposition: true, // you need to specify that!
  messages, // set locale messages
  datetimeFormats, // set date time format
  numberFormats // set number format
})

export default i18n
