<script setup>
import AuthSvc from '@/services/auth'
import HotelsTable from './components/HotelsTable.vue'
import HotelsDetailsForm from './components/HotelsDetailsForm.vue'
const isPlatformAdministrator = AuthSvc.getIsPlatformAdministrator()
</script>

<template>
  <div class="py-1 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <hotels-table v-if="isPlatformAdministrator" />
        <hotels-details-form />
      </div>
    </div>
  </div>
</template>
