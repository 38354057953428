<template>
  <el-row class="subscription-hero">
    <el-row class="w-50">
      <el-col class="subscription-hero-heading">
        <i class="subscription-icon fa-solid fa-triangle-exclamation"></i>
        <p class="subscription-end-heading">Your subscription has expired.</p>
      </el-col>
      <el-col>
        <hr class="my-1 horizontal dark" />
      </el-col>
    </el-row>
    <el-row class="mt30" :gutter="30">
      <el-col :span="8">
        <div class="hero-content">
          <p>
            Dear Kashyap Chhabria,
            <br />
            <br />
            Thank you for using our service. Your subscription period has ended, but you can
            continue enjoying our premium features by upgrading your account.
          </p>
          <el-button class="w-100 btn-lg text-sm login-button mt30" type="primary">
            UPGRADE YOUR ACCOUNT
          </el-button>
        </div>
      </el-col>
      <el-col :span="3"></el-col>
      <el-col :span="13">
        <div class="subscription-hero-image">
          <img src="../../assets/landingPage/subscriptionExpired2.svg" alt="Mobile App Interface" />
        </div>
      </el-col>
    </el-row>
  </el-row>
  <!-- <el-row class="hero">
    <el-row>
      <h4>Your free trial is over.</h4>
    </el-row>
    <el-row>
      <el-col :span="12">
        <p>
          Dear Kashyap Chhabria,
          <br />
          As your trial period ends, we're grateful for your engagement. Our integrated channel
          manager, coupled with our property management system, offers streamlined operations and
          expanded reach. With real-time updates and robust reporting, we ensure accuracy and
          provide valuable insights. Our dedicated support team is here for a smooth transition.
          Investing in our solution promises substantial returns. Let's tailor it to your needs and
          objectives. Don't miss out – contact us to unlock your properties' full potential.
        </p>
      </el-col>
      <el-col :span="12"></el-col>
    </el-row>
  </el-row> -->
</template>

<script>
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'UsersTabe',
  setup() {
    const { t } = useI18n()
    const base = reactive({})

    return {
      t,
      base
    }
  }
}
</script>

<style>
/* .userstable-max-height {
  height: calc(100vh - 205px) !important;
} */
.subscription-end-heading {
  font-size: 1.875rem;
}
.subscription-icon {
  font-size: 1.875rem;
  margin-top: 10px;
  color: orange;
  margin-right: 10px;
}
.subscription-hero-heading {
  display: flex !important;
}
.subscription-hero {
  padding: 5rem 0rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.subscription-hero-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  float: right;
}
</style>
