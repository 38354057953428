<template>
  <el-row class="contact-us privacy-policy-hero w-100">
    <el-col class="text-center">
      <h2 class="pricing-heading">Contact Us</h2>
    </el-col>
    <el-row class="w-100">
      <p>For any questions or concerns please contact us at:</p>
      <p>
        <strong>Email:</strong>
        <a href="mailto:contactus@zatastays.com">contactus@zatastays.com</a>
      </p>
      <p>
        <strong>Phone:</strong>
        +91 6281652287, +91 9642636232
      </p>
    </el-row>
  </el-row>
</template>

<script>
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'PrivacyPolicy',
  setup() {
    const { t } = useI18n()
    const base = reactive({})

    return {
      t,
      base
    }
  }
}
</script>

<style>
.contact-us {
  min-height: calc(100vh - 214px);
  max-height: calc(100vh - 214px);
}
.plan-card {
  padding: 40px;
}
.pricing-heading {
  margin-bottom: 20px;
}
.price {
  text-align: center;
  margin-bottom: 20px;
}
.plan-name {
  text-align: center;
  margin-bottom: 15px;
}
.plan-btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.plan-feature-icon {
  margin-top: 6px;
  margin-right: 10px;
  /* color: #00a3ff; */
}
.privacy-policy-hero {
  padding: 5rem 0rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.privacy-policy-hero .el-row * {
  display: block;
}
.privacy-policy-hero .h3,
h4 {
  width: 100%;
}
.privacy-policy-hero p {
  width: 100%;
}
</style>
