<template>
  <div v-loading.fullscreen.lock="base.loading" class="card">
    <div class="card-header pr-0 pb-0">
      <el-row :gutter="10">
        <el-col :span="16">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-date-picker
                v-model="base.fromDate"
                type="date"
                :placeholder="$t('common.pickADay')"
                class="w-100"
                format="DD-MM-YYYY"
                @change="handleNoOfDaysChange"
              />
            </el-col>
            <el-col v-if="!base.cardView" :span="8">
              <el-select
                v-model="base.noOfDays"
                :placeholder="$t('reservations.numberOfExtraBeds')"
                @change="handleNoOfDaysChange"
              >
                <el-option label="15" :value="15" />
                <el-option label="30" :value="30" />
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-row>
                <p class="mt2 mr10">{{ $t('reservations.cardView') }}</p>
                <el-switch
                  v-model="base.cardView"
                  :active-text="$t('yes')"
                  :inactive-text="$t('no')"
                  @change="handleCardViewChange"
                />
              </el-row>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddReservation(false)"
          >
            {{ $t('reservations.reservation') }}
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2 calendar-main">
      <ReservationsCardView
        v-if="base.cardView"
        ref="reservationsCardViewRef"
        :selectedDate="base.fromDate"
        @cardRoomClick="handleCardRoomClick"
      ></ReservationsCardView>
      <el-table
        v-if="!base.cardView"
        :data="base.reservations"
        row-key="roomNumber"
        border
        default-expand-all
        class="reservations-table-height"
        :cell-class-name="getTableRowClass"
        :scrollbar-always-on="true"
        @cell-click="handleCellClick"
      >
        <el-table-column
          fixed
          prop="roomNumber"
          :label="$t('reservations.roomNumber')"
          width="150"
        />
        <el-table-column
          v-for="monthHeading in base.monthHeadings"
          :key="monthHeading.month"
          :label="monthHeading.month"
        >
          <el-table-column
            v-for="dt in monthHeading.dates"
            :key="dt.date"
            width="75"
            :label="dt.formattedDate"
          >
            <template #default="scope">
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="getCellValue(scope.row, dt)"
                placement="top"
              >
                <div
                  style="
                    align-items: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                >
                  {{ getCellValue(scope.row, dt) }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      v-model="base.reservationFormVisible"
      destroy-on-close
      align-center
      :fullscreen="true"
      :show-close="false"
    >
      <template #header="{ close, titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass">{{ $t('reservations.title') }}</h4>
          <div>
            <el-button
              v-if="base.pastReservationsList?.length > 0"
              type="primary"
              @click="handleViewPastReservations"
            >
              {{ $t('reservations.viewPastReservations') }}
            </el-button>
            <el-button type="danger" @click="close">
              <el-icon class="el-icon--left"><CircleCloseFilled /></el-icon>
              {{ $t('close') }}
            </el-button>
          </div>
        </div>
      </template>
      <el-form
        ref="reservationRef"
        v-loading.fullscreen.lock="base.loading"
        :model="base.reservationForm"
        :rules="base.reservationRules"
        label-width="auto"
      >
        <el-row class="mt-2" :gutter="20">
          <el-col :span="24">
            <p class="text-uppercase text-m font-weight-bold">
              {{ $t('reservations.roomInformation') }}
            </p>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('reservations.roomNumber')" prop="room.roomNumber">
              <el-select
                v-model="base.reservationForm.room.roomNumber"
                :placeholder="$t('reservations.selectRoom')"
                size="large"
                @change="handleRoomChange"
              >
                <el-option
                  v-for="item in base.rooms"
                  :key="item.roomNumber"
                  :label="item.roomNumber"
                  :value="item.roomNumber"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('reservations.checkInDate')" prop="checkIn">
              <el-date-picker
                v-model="base.reservationForm.checkIn"
                type="date"
                :placeholder="$t('common.pickADay')"
                size="large"
                class="w-100"
                format="DD-MM-YYYY"
                :disabled-date="disabledDateBeforeToday"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('reservations.checkOutDate')" prop="checkOut">
              <el-date-picker
                v-model="base.reservationForm.checkOut"
                type="date"
                :placeholder="$t('common.pickADay')"
                size="large"
                class="w-100"
                format="DD-MM-YYYY"
                :disabled-date="disabledDateBeforeToday"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('reservations.numberOfAdults')">
              <el-input-number
                v-model="base.reservationForm.numberOfAdults"
                :min="1"
                :max="base.roomType.numberOfAdults"
                class="w-100"
                controls-position="right"
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('reservations.numberOfExtraBeds')">
              <el-input-number
                v-model="base.reservationForm.numberOfExtraAdults"
                :min="0"
                :max="base.roomType.numberOfExtraAdults"
                class="w-100"
                controls-position="right"
                size="large"
                @change="handleExtraAdultsChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('reservations.isCheckedIn')">
              <el-switch
                v-model="base.reservationForm.isCheckedIn"
                size="large"
                :active-text="$t('yes')"
                :inactive-text="$t('no')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('reservations.isCheckedOut')">
              <el-switch
                v-model="base.reservationForm.isCheckedOut"
                size="large"
                :active-text="$t('yes')"
                :inactive-text="$t('no')"
                :disabled="!base.reservationForm._id"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <p class="text-uppercase text-m font-weight-bold">{{ $t('reservations.tariff') }}</p>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('reservations.actualPricePerNight')">
              <el-input-number
                v-model="base.reservationForm.room.actualPrice"
                class="w-100"
                :controls="false"
                size="large"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('reservations.pricePerNight')">
              <el-input-number
                v-model="base.reservationForm.room.price"
                class="w-100"
                :controls="false"
                size="large"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <p class="text-uppercase text-m font-weight-bold">
            {{ $t('reservations.guestInformation') }}
          </p>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('title')" prop="guestDetails.title">
              <el-select
                v-model="base.reservationForm.guestDetails.title"
                :placeholder="$t('common.select')"
                size="large"
              >
                <el-option
                  v-for="item in base.titleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('reservations.guestName')" prop="guestDetails.name">
              <el-input
                v-model="base.reservationForm.guestDetails.name"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('mobileNumber')" prop="guestDetails.mobileNumber">
              <el-input
                v-model="base.reservationForm.guestDetails.mobileNumber"
                maxlength="10"
                size="large"
                autocomplete="off"
                @input="
                  () => {
                    base.reservationForm.guestDetails.mobileNumber =
                      base.reservationForm.guestDetails.mobileNumber.replace(/[^0-9]/g, '')
                  }
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('reservations.guestEmail')" prop="guestDetails.email">
              <el-input
                v-model="base.reservationForm.guestDetails.email"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('reservations.idType')" prop="guestDetails.idType">
              <el-select
                v-model="base.reservationForm.guestDetails.idType"
                :placeholder="$t('common.select')"
                size="large"
                value-key="name"
                filterable
              >
                <el-option
                  v-for="item in base.idTypes"
                  :key="item.name"
                  :label="item.displayName"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('reservations.idNumber')" prop="guestDetails.idNumber">
              <el-input
                v-model="base.reservationForm.guestDetails.idNumber"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item
                  :label="$t('reservations.idProofDocument')"
                  class="mb-0"
                  prop="guestDetails.idProofDocument.base64String"
                >
                  <!-- <el-upload
                    v-model:file-list="base.fileList"
                    class="upload-demo"
                    :show-file-list="false"
                    :on-remove="handleRemove"
                    :http-request="handleUploadRequest"
                    :on-change="handleChange"
                  >
                    <el-button type="primary">{{ $t('reservations.clickToUpload') }}</el-button>
                    <template #tip>
                      <div class="el-upload__tip">{{ $t('reservations.uploadTip') }}</div>
                    </template>
                  </el-upload> -->
                  <el-upload
                    class="upload-demo w-100"
                    drag
                    :show-file-list="false"
                    :on-remove="handleRemove"
                    :http-request="handleUploadRequest"
                    :on-change="handleChange"
                  >
                    <i class="fa-solid fa-cloud-arrow-up upload-icon-custom"></i>
                    <div class="el-upload__text">
                      {{ $t('reservations.dropFileHere') }}
                      <em>{{ $t('reservations.clickToUpload') }}</em>
                    </div>
                    <template #tip>
                      <el-row>
                        <el-col :span="12">
                          <div class="el-upload__tip">{{ $t('reservations.uploadTip') }}</div>
                        </el-col>
                        <el-col :span="12">
                          <el-button
                            v-if="base.reservationForm.guestDetails.idProofDocument?.base64String"
                            class="w-100 mt10"
                            type="default"
                            @click="downloadBase64File"
                          >
                            {{ $t('reservations.clickToDownload') }}
                          </el-button>
                        </el-col>
                      </el-row>
                    </template>
                  </el-upload>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="10">
                <el-form-item label=" " class="mt8 mb-0">
                  <el-button
                    v-if="base.reservationForm.guestDetails.idProofDocument?.base64String"
                    class="w-100"
                    type="default"
                    @click="downloadBase64File"
                  >
                    {{ $t('reservations.clickToDownload') }}
                  </el-button>
                </el-form-item>
              </el-col> -->
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <p class="text-uppercase text-m font-weight-bold">
            {{ $t('reservations.guestContactInformation') }}
          </p>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('address')" prop="guestDetails.address">
              <el-input
                v-model="base.reservationForm.guestDetails.address"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('country')" prop="guestDetails.country">
              <el-select
                v-model="base.reservationForm.guestDetails.country"
                :placeholder="$t('common.select')"
                size="large"
                value-key="country"
                filterable
                @change="handleSetStates"
              >
                <el-option
                  v-for="item in base.countries"
                  :key="item.country"
                  :label="item.country"
                  :value="item.country"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('state')" prop="guestDetails.state">
              <el-select
                v-model="base.reservationForm.guestDetails.state"
                :placeholder="$t('common.select')"
                size="large"
                filterable
              >
                <el-option v-for="item in base.states" :key="item" :label="item" :value="item" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('city')" prop="guestDetails.city">
              <el-input
                v-model="base.reservationForm.guestDetails.city"
                size="large"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('zipCode')" prop="guestDetails.zipCode">
              <el-input
                v-model="base.reservationForm.guestDetails.zipCode"
                maxlength="8"
                size="large"
                autocomplete="off"
                @input="
                  () => {
                    base.reservationForm.guestDetails.zipCode =
                      base.reservationForm.guestDetails.zipCode.replace(/[^0-9]/g, '')
                  }
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.reservationFormVisible = false">
            {{ $t('reservations.cancel') }}
          </el-button>
          <el-button type="primary" @click="handleSave">{{ $t('reservations.save') }}</el-button>
          <el-button v-if="base.reservationForm._id" type="primary" @click="handleGenerateInvoice">
            {{ $t('reservations.generateInvoice') }}
          </el-button>
        </div>
      </template>
      <el-dialog
        v-model="base.pastReservationsVisible"
        width="80%"
        :title="$t('reservations.pastReservations')"
        append-to-body
      >
        <el-table :data="base.pastReservationsList" border stripe style="width: 100%">
          <el-table-column prop="checkIn" :label="$t('reservations.checkIn')">
            <template #default="scope">
              <span>{{ getFormattedDate(scope.row.checkIn) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="checkOut" :label="$t('reservations.checkOut')">
            <template #default="scope">
              <span>{{ getFormattedDate(scope.row.checkIn) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="guestDetails.name" :label="$t('reservations.guestName')" />
          <el-table-column prop="room.roomNumber" :label="$t('reservations.roomNumber')" />
          <el-table-column label="" min-width="75">
            <template #default="scope">
              <el-button link type="primary" size="small" @click="handleGenerateInvoice(scope.row)">
                {{ $t('reservations.downloadInvoice') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Search, Plus } from '@element-plus/icons-vue'
import { ElNotification, ElMessageBox } from 'element-plus'
import { CircleCloseFilled } from '@element-plus/icons-vue'
import _ from 'lodash'
import constants from '@/constants'
import RoomSvc from '@/services/room'
import RoomTypeSvc from '@/services/roomType'
import ReservationSvc from '@/services/reservation'
import InvoiceSvc from '@/services/invoice'
import AuthSvc from '@/services/auth'
import calendarUtil from '@/utils/calendarUtil'
import moment from 'moment'
import common from '@/utils/common'
import ReservationsCardView from './ReservationsCardView.vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ReservationsTable',
  components: {
    ReservationsCardView,
    CircleCloseFilled
  },
  setup() {
    const { t } = useI18n()
    const reservationRef = ref(null)
    const reservationsCardViewRef = ref(null)
    const base = reactive({
      loading: false,
      reservationFormVisible: false,
      pastReservationsList: [],
      pastReservationsVisible: false,
      search: {
        text: '',
        key: 'roomNumber'
      },
      reservationForm: {},
      reservations: [],
      rooms: [],
      dates: [],
      monthHeadings: [],
      titleOptions: constants.TITLE_OPTIONS,
      genderOptions: constants.GENDER_OPTIONS,
      countries: constants.COUNTRIES,
      idTypes: constants.ID_TYPES,
      states: [],
      actualPrice: 0,
      dayWiseReservations: [],
      fromDate: moment().startOf('day')._d,
      noOfDays: 15,
      reservationRules: {
        'room.roomNumber': [{ required: true, message: ' ', trigger: 'blur' }],
        checkIn: [{ required: true, message: ' ', trigger: 'blur' }],
        checkOut: [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.title': [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.name': [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.mobileNumber': [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.email': [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.idType': [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.idNumber': [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.address': [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.country': [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.state': [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.city': [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.zipCode': [{ required: true, message: ' ', trigger: 'blur' }],
        'guestDetails.idProofDocument.base64String': [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
      cardView: false,
      roomType: {},
      fileList: []
    })

    onMounted(async () => {
      console.log('ReservationsCardView', reservationsCardViewRef.value)
      base.loading = true
      base.dates = calendarUtil.generateDatesArray(base.noOfDays, base.fromDate)
      const monthHeadings = _.uniqBy(base.dates, 'month')
      monthHeadings.forEach(mt => {
        const mDates = base.dates.filter(dt => dt.month === mt.month)
        base.monthHeadings.push({ month: mt.month, dates: mDates })
      })
      console.log('monthHeadings', base.monthHeadings)
      console.log('base.dates', base.dates)
      await fetchReservations()
      await fetchRoomTypes()
      fetchRooms()
      // handleAddReservation()
    })

    const handleNoOfDaysChange = async () => {
      if (!base.cardView) {
        console.log('base.fromDate', base.fromDate)
        base.dates = calendarUtil.generateDatesArray(base.noOfDays, base.fromDate)
        const monthHeadings = _.uniqBy(base.dates, 'month')
        const headings = []
        monthHeadings.forEach(mt => {
          const mDates = base.dates.filter(dt => dt.month === mt.month)
          headings.push({ month: mt.month, dates: mDates })
        })
        base.monthHeadings = headings
        console.log('monthHeadings', base.monthHeadings)
        await fetchReservations()
        fetchRoomTypes()
        fetchRooms()
      }
    }

    const disabledDateBeforeToday = time => {
      return time.getTime() < new Date().setHours(0, 0, 0, 0)
    }

    const fetchReservations = async () => {
      const options = AuthSvc.getOptions()
      const fromDate = base.dates[0].date
      const toDate = base.dates[base.dates.length - 1].date
      const query = {
        checkIn: {
          $gte: fromDate,
          $lt: toDate
        }
        // isCheckedOut: false
      }
      await ReservationSvc.find(options, query, undefined, 1, 10000)
        .then(response => {
          base.dayWiseReservations = response.data.records
          console.log('base.reservations', response.data.records)
        })
        .catch(error => {
          base.loading = false
          console.log('ReservationsTable fetchReservations error => ', error)
          common.showErrors(error)
        })
    }

    const fetchRoomTypes = async () => {
      const options = AuthSvc.getOptions()
      await RoomTypeSvc.find(options)
        .then(response => {
          base.roomTypes = response.data.records
        })
        .catch(error => {
          base.loading = false
          console.log('ReservationsTable fetchRoomTypes error => ', error)
          common.showErrors(error)
        })
    }

    const fetchRooms = () => {
      const options = AuthSvc.getOptions()
      RoomSvc.find(options, undefined, undefined, 1, 10000)
        .then(response => {
          base.rooms = response.data.records
          setReservations()
        })
        .catch(error => {
          base.loading = false
          console.log('ReservationsTable fetchRooms error => ', error)
          common.showErrors(error)
        })
    }

    const setReservations = () => {
      const reservations = []
      const floors = _.uniq(base.rooms.map(room => room.floor))
      floors.forEach(floor => {
        const floorRooms = base.rooms.filter(room => room.floor === floor)
        const children = []
        floorRooms.forEach(floorRoom => {
          const roomReservations = base.dayWiseReservations.filter(
            r => r.room.roomNumber === floorRoom.roomNumber && !r.isCheckedOut
          )
          console.log('roomReservations', roomReservations)
          const child = { roomNumber: floorRoom.roomNumber }
          roomReservations.forEach(r => {
            child[r.checkIn] = r
          })
          children.push(child)
        })
        reservations.push({
          roomNumber: `Floor ${floor}`,
          isFloor: true,
          children
        })
      })
      base.reservations = reservations
      base.loading = false
      console.log('base.reservations', base.reservations)
      console.log('floors', floors)
    }

    const handleCreateNewReservation = (roomNumber, checkIn = '') => {
      console.log('base.rooms', base.rooms)
      const room = base.rooms.find(room => room.roomNumber === roomNumber)
      console.log('room, roomNumber', room, roomNumber)
      return {
        room: {
          roomId: room?._id || '',
          roomNumber: room?.roomNumber || '',
          actualPrice: 0,
          taxPercentage: 0,
          price: 0
        },
        checkIn: checkIn,
        checkOut: '',
        guestDetails: {
          title: '',
          name: '',
          mobileNumber: '',
          idType: 'aadharCard',
          idNumber: '',
          idProofDocument: {},
          address: '',
          city: '',
          state: '',
          zipCode: '',
          email: ''
        },
        numberOfAdults: 2,
        numberOfExtraAdults: 0,
        isCheckedIn: false,
        isCheckedOut: false,
        totalPriceWithoutTax: 0,
        totalPriceWithTax: 0
      }
    }

    const handleAddReservation = (roomNumber, checkIn) => {
      if (roomNumber === false) {
        base.pastReservationsList = []
      }
      base.reservationForm = handleCreateNewReservation(roomNumber, checkIn)
      if (base.reservationForm.room.roomId) {
        handleRoomChange()
      }
      base.reservationFormVisible = true
    }

    const handleGenerateInvoice = row => {
      if (row) {
        handleGenerateAndDownload(row)
      } else {
        ElMessageBox.confirm(
          'Please ensure all changes are saved before generating the invoice. Do you want to proceed?',
          'Confirm Invoice Generation',
          {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(() => {
          handleGenerateAndDownload()
        })
      }
    }

    const handleGenerateAndDownload = row => {
      base.loading = true
      const options = AuthSvc.getOptions()
      const invoiceId = row?.invoiceId || base.reservationForm._id
      // const invoiceNumber = row?.invoiceNumber || base.reservationForm.invoiceNumber
      InvoiceSvc.generate(options, invoiceId)
        .then(res => {
          base.loading = false
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob) // Create a temporary URL for the file
          const newWindow = window.open(url, '_blank') // Open the file in a new tab or window

          if (newWindow) {
            newWindow.onload = () => {
              newWindow.print() // Trigger the print dialog once the file is loaded
            }
          }

          // Revoke the Object URL to release memory
          window.URL.revokeObjectURL(url)
          ElNotification({
            type: 'success',
            message: 'Invoice generated successfully!'
          })
        })
        .catch(error => {
          base.loading = false
          console.log('ReservationsTable handleGenerateInvoice error => ', error)
          common.showErrors(error)
        })
    }

    const handleSave = () => {
      reservationRef.value.validate(valid => {
        if (valid) {
          const options = AuthSvc.getOptions()
          console.log(JSON.stringify(base.reservationForm))
          base.loading = true
          InvoiceSvc.save(options, base.reservationForm)
            .then(async response => {
              console.log('ReservationsTable handleSave ==>', response.data)
              const message =
                response.status === 201
                  ? 'Reservation created successfully'
                  : 'Reservation updated successfully'
              ElNotification({
                message,
                type: 'success'
              })
              if (base.cardView) {
                base.loading = false
                reservationsCardViewRef.value.fetchReservations()
              } else {
                await fetchReservations()
                setReservations()
              }
              base.reservationFormVisible = false
            })
            .catch(error => {
              base.loading = false
              console.log('ReservationsTable handleSave error => ', error)
              common.showErrors(error)
            })
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const handleSetStates = () => {
      if (base.reservationForm.guestDetails.country) {
        const country = constants.COUNTRIES.find(
          f => f.country === base.reservationForm.guestDetails.country
        )
        base.states = country?.states || []
      } else {
        base.states = []
      }
    }

    const handleCardRoomClick = room => {
      base.pastReservationsList = base.dayWiseReservations.filter(
        r => r.room.roomNumber === room.roomNumber && r.checkIn === base.fromDate && r.isCheckedOut
      )
      if (room.invoice?.invoiceId) {
        const options = AuthSvc.getOptions()
        InvoiceSvc.findById(options, room.invoice.invoiceId)
          .then(res => {
            console.log('res => ', res.data)
            base.reservationForm = res.data
            base.reservationFormVisible = true
          })
          .catch(error => {
            base.loading = false
            console.log('ReservationsTable handleCellClicks error => ', error)
            common.showErrors(error)
          })
      } else {
        handleAddReservation(room?.roomNumber, base.fromDate)
      }
    }

    const handleCellClick = (row, col) => {
      console.log('args', row, col, row[col.rawColumnKey])
      if (!row.isFloor) {
        base.pastReservationsList = base.dayWiseReservations.filter(
          r =>
            r.room.roomNumber === row.roomNumber && r.checkIn === col.rawColumnKey && r.isCheckedOut
        )
        console.log('base.pastReservationsList', base.pastReservationsList)
        if (row[col.rawColumnKey]?.invoiceId) {
          const options = AuthSvc.getOptions()
          InvoiceSvc.findById(options, row[col.rawColumnKey]?.invoiceId)
            .then(res => {
              console.log('res => ', res.data)
              base.reservationForm = res.data
              base.reservationFormVisible = true
            })
            .catch(error => {
              base.loading = false
              console.log('ReservationsTable handleCellClicks error => ', error)
              common.showErrors(error)
            })
        } else {
          handleAddReservation(row.roomNumber, col.rawColumnKey)
        }
      }
    }

    const handleCardViewChange = () => {
      if (!base.cardView) {
        handleNoOfDaysChange()
      }
    }

    const getTableRowClass = args => {
      // let rowClass = 'calendar-cell text-uppercase text-xs font-weight-normal opacity-7'
      // if ((index === 0 && room.roomNumber !== '100') || (index === 5 && room.roomNumber !== '101')) {
      //   rowClass += ' room-date-reserved'
      // } else if (dt.isWeekend) {
      //   rowClass += ' calender-weekend-header'
      // }
      // return rowClass
      const colDate = base.dates.find(dt => dt.formattedDate === args.column.label)
      let rowClass = ''
      if (args.row.roomNumber === '201') {
        // console.log('args', args.column.getColumnIndex(), args.column, base.dates[args.column.getColumnIndex()])
        // console.log('colDate', colDate, args.row)
        // console.log('args.row[colDate.date]', args.row[colDate.date])
      }
      if (!args.row.isFloor) {
        rowClass = 'calendar-cell'
      }
      if (colDate && args.row[colDate.date]) {
        rowClass += ' room-date-reserved'
      }
      //  else if (colDate?.isWeekend) {
      //   rowClass += ' calender-weekend-header'
      // }
      return rowClass
    }

    const handleExtraAdultsChange = () => {
      const selectedRoom = base.rooms.find(
        room => room.roomNumber === base.reservationForm.room.roomNumber
      )
      const roomType = base.roomTypes.find(type => type._id === selectedRoom.roomType.id)
      base.reservationForm.room.taxPercentage = roomType.taxPercentage
      base.reservationForm.room.actualPrice =
        roomType.ratePerNight +
        base.reservationForm.numberOfExtraAdults * roomType.chargePerExtraAdult
      base.reservationForm.room.price = _.clone(base.reservationForm.room.actualPrice)
    }

    const handleRoomChange = () => {
      const selectedRoom = base.rooms.find(
        room => room.roomNumber === base.reservationForm.room.roomNumber
      )
      base.reservationForm.room.roomId = selectedRoom._id
      base.roomType = base.roomTypes.find(type => type._id === selectedRoom?.roomType.id)
      console.log('base.roomType', base.roomType)
      base.reservationForm.room.taxPercentage = base.roomType?.taxPercentage || 0
      base.reservationForm.room.actualPrice = base.roomType
        ? base.roomType.ratePerNight +
          base.reservationForm.numberOfExtraAdults * base.roomType.chargePerExtraAdult
        : 0
      base.reservationForm.room.price = _.clone(base.reservationForm.room.actualPrice)
    }

    const getCellValue = (row, dt) => {
      if (!row.isFloor && row[dt.date]) {
        return `${row[dt.date].guestDetails?.title} ${row[dt.date].guestDetails?.name} | ${row[dt.date].totalPriceWithTax}`
      }
      return ''
    }

    const handleRemove = () => {
      base.reservationForm.guestDetails.idProofDocument = {}
    }

    const handleUploadRequest = () => {
      console.log('uploading..')
    }

    const handleChange = file => {
      base.loading = true
      // base.file = file.raw
      // console.log('base.file', base.file)
      convertToBase64(file.raw)
    }

    const downloadBase64File = () => {
      if (base.reservationForm.guestDetails.idProofDocument?.base64String) {
        const base64String = `data:${base.reservationForm.guestDetails.idProofDocument.mimeType};base64,${base.reservationForm.guestDetails.idProofDocument.base64String}`
        // Create a temporary anchor element
        const link = document.createElement('a')

        // Set the href to the Base64 string and specify the file type in the data URL
        link.href = base64String
        link.download = base.reservationForm.guestDetails.idProofDocument.name // Set the desired file name

        // Append the link to the document and trigger a click event to download
        document.body.appendChild(link)
        link.click()

        // Remove the link after download
        document.body.removeChild(link)
      }
    }

    const convertToBase64 = file => {
      if (!file) {
        base.loading = false
        // this.$message.error('Please select a file first!')
        return
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1] // Extract Base64 part
        // console.log('base64String', base64String)
        base.reservationForm.guestDetails.idProofDocument = {
          base64String,
          name: file.name,
          size: file.size,
          mimeType: file.type
        }
        base.loading = false
      }
      reader.onerror = () => {
        // this.$message.error('Error reading file')
        base.loading = false
      }

      reader.readAsDataURL(file)
    }

    const handleViewPastReservations = () => {
      base.pastReservationsVisible = true
    }

    const getFormattedDate = date => {
      return calendarUtil.getShortDateFormat(date)
    }

    return {
      t,
      base,
      reservationRef,
      reservationsCardViewRef,
      Search,
      Plus,
      handleSetStates,
      handleAddReservation,
      disabledDateBeforeToday,
      handleSave,
      getTableRowClass,
      handleCellClick,
      handleRoomChange,
      fetchRoomTypes,
      handleExtraAdultsChange,
      getCellValue,
      handleNoOfDaysChange,
      handleCardViewChange,
      handleCardRoomClick,
      handleRemove,
      handleChange,
      handleUploadRequest,
      downloadBase64File,
      handleGenerateInvoice,
      handleViewPastReservations,
      getFormattedDate
    }
  }
}
</script>

<style>
.reservations-table-height {
  height: calc(100vh - 200px) !important;
}
.calendar-main {
  margin: 1.5rem;
}
.calendar-row {
  padding: 10px;
}
.calendar-border {
  border: solid;
  border-width: thin;
}
.room-date-reserved {
  /* background-image: linear-gradient(310deg, #d60808, #ff6690); */
  background-color: rgb(64, 201, 112) !important;
  color: white;
  font-weight: 500;
}
.calendar-cell {
  cursor: pointer;
}
.calender-weekend-header {
  background-color: rgb(245, 233, 233) !important;
  color: rgb(51, 51, 51) !important;
}
.calendar-header {
  border-right: 1px solid rgb(192, 192, 192);
  background-color: #f3f3f3 !important;
  color: rgb(51, 51, 51) !important;
}
.el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}
</style>
