<template>
  <el-row class="terms-hero w-100">
    <el-col class="text-center">
      <h2 class="terms-heading">Terms and Conditions</h2>
    </el-col>
    <el-row class="w-100" :gutter="20">
      <p>Effective Date: 01/01/2025</p>

      <p>
        These Terms and Conditions ("Terms") form a legally binding agreement between the users
        ("User(s)", "you", "your", "customer(s)", "yourself") and NIKHSHEP IT SERVICES LLP
        ("ZATASTAYS," "we", "us" or "our"). These Terms govern the use of the website
        www.zatastays.com, Zatastays mobile application, or any other websites through which
        Zatastays makes the Services available (collectively referred to as "Platform").
      </p>

      <h3>1. Acceptance of Terms</h3>
      <p>
        By using the Platform, you agree to abide by these Terms and Conditions, whether or not you
        register as a user of the Services. These Terms govern your access to and use of the
        Platform and the Services. Please review these Terms carefully before accessing, using,
        obtaining, or availing of any Services from Zatastays.
      </p>

      <h4>1.1 Right to Modify</h4>
      <p>
        Zatastays reserves the right to amend these Terms at its sole discretion. It is your
        responsibility to stay informed of any changes to the Terms, and your continued use of the
        Platform and Services will constitute acceptance of these terms and any modifications
        thereafter. Failure to adhere to these Terms when using the Platform may result in civil and
        criminal penalties.
      </p>

      <h3>2. Modification of Services</h3>
      <p>
        We reserve the right, in our sole discretion, to modify or replace any of these Terms, or
        change, suspend, or discontinue the Services at any time by posting a notice on the Platform
        or by sending you a notice via e-mail or another appropriate means of electronic
        communication.
      </p>

      <h3>3. Account Registration</h3>
      <h4>3.1 Account Creation</h4>
      <p>
        To sign up for the Services, you may be required to register for an account on the Platform
        ("Account"). You must provide accurate and complete information and keep your Account
        information updated.
      </p>

      <h4>3.2 Account Restrictions</h4>
      <p>You shall not:</p>
      <ul>
        <li>Select or use a username to impersonate another person</li>
        <li>Use a name without appropriate authorization</li>
        <li>Use a username that is offensive, vulgar, or obscene</li>
      </ul>

      <h4>3.3 Account Responsibility</h4>
      <p>
        You are solely responsible for the activity that occurs on your Account and for keeping your
        Account password secure. You must notify us immediately of any change in your eligibility,
        breach of security, or unauthorized use of your Account.
      </p>

      <h3>4. Termination</h3>
      <p>
        We may, in our discretion and without liability to you, with or without cause, with or
        without prior notice and at any time:
      </p>
      <ul>
        <li>Terminate these Terms or your access to the Platform and Services</li>
        <li>Deactivate or cancel your Zatastays Account</li>
      </ul>
      <p>
        Upon termination, we will promptly pay you any amounts we reasonably determine we owe you.
        You may cancel your Account at any time via the 'Cancel Account' feature or by sending an
        email to contactus@zatastays.com.
      </p>

      <h3>5. Free Trial and Fees</h3>
      <h4>5.1 Free Trial</h4>
      <p>
        You may start using our App with a free trial lasting one week, or as specified during
        sign-up. You will be billed for your yearly membership fee at the end of the free trial
        unless you uninstall the App prior to the end of the trial period.
      </p>

      <h4>5.2 Membership and Pricing</h4>
      <ul>
        <li>You may cancel your membership at any time</li>
        <li>No refunds or credits are provided for partial membership periods</li>
        <li>We may change service plans and prices from time to time</li>
      </ul>

      <h3>6. Rules of Conduct</h3>
      <p>
        We grant you a personal, limited, nonexclusive, and non-transferable license to access and
        use the Services only as expressly permitted in these Terms.
      </p>

      <h4>6.1 Prohibited Activities</h4>
      <p>When using the App, you agree not to:</p>
      <ul>
        <li>Use the App for any illegal purpose</li>
        <li>Violate any applicable laws of India or international laws</li>
        <li>Promote or encourage illegal activity</li>
        <li>Attempt to gain unauthorized access to the App or connected networks</li>
        <li>Modify, adapt, translate, or reverse engineer any part of the App</li>
      </ul>

      <h3>7. Contact Information</h3>
      <p>
        If you have any questions about these Terms or the Platform, please contact us at
        <a href="mailto:contactus@zatastays.com">contactus@zatastays.com</a>
        .
      </p>

      <p>By using our Platform, you agree to the terms of these Terms and Conditions.</p>
    </el-row>
  </el-row>
</template>

<script>
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'TermsConditions',
  setup() {
    const { t } = useI18n()
    const base = reactive({})

    return {
      t,
      base
    }
  }
}
</script>

<style>
.terms-hero {
  padding: 5rem 0rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.terms-hero .el-row * {
  display: block;
}
.terms-heading {
  margin-bottom: 20px;
}
.terms-hero h3,
.terms-hero h4 {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.terms-hero p {
  width: 100%;
  margin-bottom: 15px;
}
.terms-hero ul {
  margin-left: 20px;
  margin-bottom: 15px;
}
</style>
