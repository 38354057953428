<template>
  <div class="card">
    <div class="card-header pr-0 pb-0">
      <el-row>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-date-picker
                v-model="base.fromDate"
                type="date"
                :placeholder="$t('common.pickADay')"
                size="large"
                class="w-100"
                format="DD-MM-YYYY"
                @change="handleNoOfDaysChange"
              />
            </el-col>
            <!-- <el-col :span="12">
              <el-select
                v-model="base.noOfDays"
                size="large"
                placeholder="No. Of Days"
                @change="handleNoOfDaysChange"
              >
                <el-option label="15" :value="15" />
                <el-option label="30" :value="30" />
                <el-option label="45" :value="45" />
                <el-option label="60" :value="60" />
              </el-select>
            </el-col> -->
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddAttendance"
          >
            Attendance
          </el-button>
        </el-col> -->
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2 calendar-main">
      <el-table
        :data="base.staffDetails"
        row-key="staffId"
        border
        default-expand-all
        max-height="calc(100vh - 200px)"
        :stripe="true"
        :scrollbar-always-on="true"
        :cell-class-name="getTableRowClass"
        @cell-click="handleCellClick"
      >
        <el-table-column fixed prop="fullName" :label="$t('staffDetails.staffName')" width="250" />
        <!-- <el-table-column
          v-for="monthHeading in base.monthHeadings"
          :key="monthHeading.month"
          :label="monthHeading.month"
        > -->
        <el-table-column v-for="dt in base.dates" :key="dt.date" :label="dt.formattedDate">
          <template #default="scope">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="getCellTooltip(scope.row, dt)"
              placement="top"
            >
              <div
                style="
                  align-items: center;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                "
              >
                {{ getCellValue(scope.row, dt) }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- </el-table-column> -->
      </el-table>
    </div>
    <el-dialog
      v-model="base.attendanceFormVisible"
      title="Staff Attendance"
      width="90%"
      destroy-on-close
      align-center
    >
      <el-form :model="base.attendanceForm" label-position="top">
        <!-- <el-row class="mt-2" :gutter="20">
          <el-col :span="24">
            <p class="text-uppercase text-sm">Room Information</p>
          </el-col>
        </el-row> -->
        <el-row :gutter="20" class="mt10">
          <el-col :span="8">
            <el-form-item :label="$t('staffDetails.staffName')">
              <el-select
                v-model="base.attendanceForm.staffId"
                :placeholder="$t('common.select')"
                size="large"
                @change="handleStaffIdChange"
              >
                <el-option
                  v-for="item in base.staffDetails"
                  :key="item.staffId"
                  :label="item.fullName"
                  :value="item.staffId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('staffDetails.attendanceDate')">
              <el-date-picker
                v-model="base.attendanceForm.date"
                type="date"
                :placeholder="$t('common.pickADay')"
                size="large"
                class="w-100"
                format="DD-MM-YYYY"
                :disabled-date="disabledDateBeforeToday"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('staffDetails.isAbsent')">
              <el-switch
                v-model="base.attendanceForm.isAbsent"
                active-text="Yes"
                inactive-text="No"
                @change="handleIsAbsentChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('checkInTime')">
              <el-time-picker
                v-model="base.attendanceForm.checkInTime"
                value-format="HH:mm:ss"
                :disabled="base.attendanceForm.isAbsent"
                size="large"
                class="w-100"
                arrow-control
                :label="$t('common.pickATime')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('checkOutTime')">
              <el-time-picker
                v-model="base.attendanceForm.checkOutTime"
                value-format="HH:mm:ss"
                :disabled="base.attendanceForm.isAbsent"
                size="large"
                class="w-100"
                arrow-control
                :label="$t('common.pickATime')"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.attendanceFormVisible = false">
            {{ $t('reservations.cancel') }}
          </el-button>
          <el-button type="primary" @click="handleSave">{{ $t('reservations.save') }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import { Search, Plus } from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'
// import _ from 'lodash'
import StaffDetailSvc from '@/services/staffDetail'
import StaffAttendanceSvc from '@/services/staffAttendance'
import AuthSvc from '@/services/auth'
import calendarUtil from '@/utils/calendarUtil'
import moment from 'moment'
import common from '@/utils/common'
import { useI18n } from 'vue-i18n'

export default {
  name: 'StaffAttendanceTable',
  setup() {
    const { t } = useI18n()
    const base = reactive({
      attendanceFormVisible: false,
      attendanceForm: {},
      staffDetails: [],
      dates: [],
      dayWiseAttendance: [],
      fromDate: moment().startOf('week')._d,
      noOfDays: 7,
      loading: false
    })

    onMounted(async () => {
      handleNoOfDaysChange(true)
    })

    const handleNoOfDaysChange = async (isFromMounted = false) => {
      base.loading = true
      base.dates = calendarUtil.generateDatesArray(base.noOfDays, base.fromDate)
      await fetchStaffAttendance()
      if (isFromMounted) {
        fetchStaffDetails()
      } else {
        base.loading = false
      }
    }

    const fetchStaffDetails = () => {
      const options = AuthSvc.getOptions()
      StaffDetailSvc.find(options, {}, undefined, 1, 10000)
        .then(response => {
          base.staffDetails = response.data.records.map(r => {
            r.fullName = `${r.firstName} ${r.lastName}`
            return r
          })
          base.loading = false
        })
        .catch(error => {
          base.loading = false
          console.log('fetchStaffDetails fetch error => ', error)
          common.showErrors(error)
        })
    }

    const disabledDateBeforeToday = time => {
      return time.getTime() < new Date().setHours(0, 0, 0, 0)
    }

    const fetchStaffAttendance = async () => {
      const options = AuthSvc.getOptions()
      console.log('base.dates', base.dates)
      const fromDate = base.dates[0].date
      const toDate = base.dates[base.dates.length - 1]
      console.log('toDate', toDate)
      const query = {
        date: {
          $gte: fromDate,
          $lt: toDate.nextDay
        }
      }
      await StaffAttendanceSvc.find(options, query, undefined, 1, 10000)
        .then(response => {
          base.dayWiseAttendance = response.data.records
        })
        .catch(error => {
          base.loading = false
          console.log('StaffAttendanceTable fetch error => ', error)
          common.showErrors(error)
        })
    }

    const handleCreateNewAttendanceForm = (date, staffDetail) => {
      return {
        name: staffDetail?.fullName || '',
        staffId: staffDetail?.staffId || '',
        checkInTime: '',
        checkOutTime: '',
        date,
        isAbsent: false
      }
    }

    const handleAddAttendance = (date, staffDetail) => {
      base.attendanceForm = handleCreateNewAttendanceForm(date, staffDetail)
      base.attendanceFormVisible = true
    }

    const handleSave = () => {
      const options = AuthSvc.getOptions()
      // let attendanceForm = _.clone(base.attendanceForm)
      StaffAttendanceSvc.save(options, base.attendanceForm)
        .then(response => {
          base.attendanceFormVisible = false
          const message =
            response.status === 201
              ? 'Attendance created successfully'
              : 'Attendance updated successfully'
          fetchStaffAttendance()
          ElNotification({
            message,
            type: 'success'
          })
        })
        .catch(error => {
          console.log('AttendanceTable handleSave error => ', error)
          common.showErrors(error)
        })
    }

    const handleCellClick = (row, col) => {
      // console.log('args', row, col, col.rawColumnKey)
      if (col.rawColumnKey) {
        const attendance = base.dayWiseAttendance.find(
          r => r.date === col.rawColumnKey && row.staffId === r.staffId
        )
        if (attendance) {
          base.attendanceForm = attendance
          base.attendanceFormVisible = true
          // const options = AuthSvc.getOptions()
          // StaffAttendanceSvc.findById(options, row._id)
          //   .then(res => {
          //     console.log('res', res.data)
          //     const attendanceForm = res.data
          //     attendanceForm.checkInTime = ''
          //     attendanceForm.checkOutTime = ''
          //     attendanceForm.date = col.rawColumnKey
          //     attendanceForm.isAbsent = false
          //     base.attendanceForm = attendanceForm
          //     base.attendanceFormVisible = true
          //   })
          //   .catch(error => {
          //     console.log('StaffAttendanceTable handleCellClick error => ', error)
          //     common.showErrors(error)
          //   })
        } else {
          handleAddAttendance(col.rawColumnKey, row)
        }
      }
    }

    const getTableRowClass = args => {
      // console.log('args.column', args.column.rawColumnKey, args.row)
      if (args.column.rawColumnKey) {
        const attendance = base.dayWiseAttendance.find(
          r => r.date === args.column.rawColumnKey && args.row.staffId === r.staffId
        )
        let rowClass = attendance
          ? attendance.isAbsent
            ? 'absent'
            : 'room-date-reserved'
          : 'calendar-cell'
        return rowClass
      } else {
        return ''
      }
    }

    const getCellTooltip = (row, dt) => {
      const attendance = base.dayWiseAttendance.find(
        r => r.date === dt.date && row.staffId === r.staffId
      )
      // console.log('attendance', attendance)
      return attendance
        ? attendance.isAbsent
          ? 'Absent'
          : `Check In: ${attendance.checkInTime}; Check Out: ${attendance.checkOutTime}`
        : 'N/A'
    }

    const getCellValue = (row, dt) => {
      const attendance = base.dayWiseAttendance.find(
        r => r.date === dt.date && row.staffId === r.staffId
      )
      return attendance ? (attendance.isAbsent ? 'A' : 'P') : 'N/A'
    }

    const handleStaffIdChange = value => {
      base.attendanceForm.name = base.staffDetails.find(staff => staff.staffId === value).fullName
    }

    const handleIsAbsentChange = () => {
      if (base.attendanceForm.isAbsent) {
        base.attendanceForm.checkInTime = ''
        base.attendanceForm.checkOutTime = ''
      }
    }

    return {
      t,
      base,
      Search,
      Plus,
      handleAddAttendance,
      disabledDateBeforeToday,
      handleSave,
      getTableRowClass,
      handleCellClick,
      getCellTooltip,
      getCellValue,
      handleNoOfDaysChange,
      handleStaffIdChange,
      handleIsAbsentChange
    }
  }
}
</script>

<style>
.table-max-height {
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
}
.calendar-main {
  margin: 1.5rem;
}
.calendar-row {
  padding: 10px;
}
.calendar-border {
  border: solid;
  border-width: thin;
}
.room-date-reserved {
  /* background-image: linear-gradient(310deg, #d60808, #ff6690); */
  background-color: rgb(64, 201, 112) !important;
  color: white;
  font-weight: 500;
  cursor: pointer;
}
.absent {
  /* background-image: linear-gradient(310deg, #d60808, #ff6690); */
  background-color: rgb(247, 96, 93) !important;
  color: white;
  font-weight: 500;
  cursor: pointer;
}
.calendar-cell {
  cursor: pointer;
}
.calender-weekend-header {
  background-color: rgb(245, 233, 233) !important;
  color: rgb(51, 51, 51) !important;
}
.calendar-header {
  border-right: 1px solid rgb(192, 192, 192);
  background-color: #f3f3f3 !important;
  color: rgb(51, 51, 51) !important;
}
.el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
</style>
