<template>
  <div class="card">
    <div class="card-header px-2 pb-0">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="base.search.text"
            :placeholder="$t('common.inputPlaceholder')"
            clearable
            class="input-with-select"
            @keyup.enter="onSearch"
          >
            <template #prepend>
              <el-select
                v-model="base.search.key"
                :placeholder="$t('common.select')"
                style="width: 125px"
              >
                <el-option :label="$t('name')" value="name" />
                <el-option :label="$t('code')" value="code" />
                <el-option :label="$t('email')" value="emailId" />
              </el-select>
            </template>
            <template #append>
              <el-button :icon="Search" @click="onSearch" />
            </template>
          </el-input>
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-table
        ref="tableRef"
        class="hotelstable-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :data="base.data.records"
        :stripe="true"
        :scrollbar-always-on="true"
        style="width: 100%"
      >
        <el-table-column prop="name" :label="$t('name')" min-width="100" />
        <el-table-column prop="code" :label="$t('code')" min-width="100" />
        <el-table-column :label="$t('email')" prop="emailId" min-width="150" />
        <el-table-column :label="$t('address')" prop="address" min-width="200" />
        <el-table-column :label="$t('phone')" prop="phone" min-width="100" />
        <el-table-column :label="$t('mobile')" prop="mobile" min-width="100" />
        <el-table-column :label="$t('website')" prop="website" min-width="100" />
        <el-table-column prop="isActive" :label="$t('isActive')" min-width="100">
          <template #default="scope">
            <span v-if="scope.row.isActive" class="badge bg-gradient-success">{{ $t('yes') }}</span>
            <span v-else class="badge bg-gradient-secondary">{{ $t('no') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Created At" prop="createdAt" min-width="150">
          <template #default="scope">
            <span>{{ getFormattedDate(scope.row.startDate) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-pagination
        :current-page="base.data.cursor.currentPage"
        size="small"
        class="m-t-10 mb2 ml10"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="base.data.cursor.perPage"
        :page-sizes="base.pageSizes"
        :total="parseInt(base.data.cursor.totalRecords)"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import { Search } from '@element-plus/icons-vue'
import TenantSvc from '@/services/tenants'
import AuthSvc from '@/services/auth'
import calendarUtil from '@/utils/calendarUtil'
import common from '@/utils/common'
import constants from '@/constants'
import _ from 'lodash'
import { useI18n } from 'vue-i18n'

export default {
  name: 'HotelsTable',
  setup() {
    const { t } = useI18n()
    const base = reactive({
      search: {
        text: '',
        key: 'name'
      },
      data: {
        records: [],
        cursor: { currentPage: 0, perPage: 0, totalRecords: 0 }
      },
      pageSizes: constants.DEFAULT_PAGE_SIZES
    })

    const local = {
      currentQuery: undefined,
      sort: {},
      fields: undefined
    }

    const onSearch = () => {
      let q = local.currentQuery
      if (base.search.text) {
        q = {}
        local.currentPage = 1
        // if (searchKeyType === 'date') {
        //   q[searchKey] = {
        //     $gte: QueryBuilder.getDate(searchText, 1),
        //     $lt: QueryBuilder.getDate(searchText, 2)
        //   }
        // } else {
        //   q[searchKey] = { $regex: '.*' + searchText + '.*', $options: 'i' }
        // }
        q[base.search.key] = {
          $regex: '.*' + base.search.text + '.*',
          $options: 'i'
        }
      } else {
        q = {}
      }
      if (!_.isEqual(q, local.currentQuery)) {
        local.currentQuery = q
        fetchTenants(
          local.currentQuery,
          local.fields,
          base.data.cursor.currentPage,
          base.data.cursor.perPage,
          local.sort
        )
      }
    }

    onMounted(() => {
      fetchTenants()
    })

    const fetchTenants = (query, projection, page, limit, sort) => {
      const options = AuthSvc.getOptions()
      TenantSvc.find(options, query, projection, page, limit, sort)
        .then(response => {
          base.data = response.data
        })
        .catch(error => {
          console.log('HotelsTable fetchTenants error => ', error)
          common.showErrors(error)
        })
    }

    const getFormattedDate = date => {
      return calendarUtil.getShortDateFormat(date)
    }

    const handleCurrentPageChange = val => {
      base.data.cursor.currentPage = val
      fetchTenants(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    const handlePageSizeChange = val => {
      base.data.cursor.perPage = val
      fetchTenants(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    return {
      t,
      base,
      Search,
      getFormattedDate,
      handleCurrentPageChange,
      handlePageSizeChange,
      onSearch
    }
  }
}
</script>

<style>
.hotelstable-max-height {
  height: calc(100vh - 205px) !important;
}
</style>
