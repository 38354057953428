<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Breadcrumbs from '../Breadcrumbs.vue'
import Auth from '@/services/auth'
import LocalStorageSvc from '@/services/localStorage'
import { useRouter } from 'vue-router'

// const showMenu = ref(false)
const store = useStore()
const isRTL = computed(() => store.state.isRTL)
const router = useRouter()

const route = useRoute()
const fullName = Auth.getFullname()

const currentRouteName = computed(() => {
  return route.name
})
const currentDirectory = computed(() => {
  let dir = route.path.split('/')[1]
  return dir.charAt(0).toUpperCase() + dir.slice(1)
})

const minimizeSidebar = () => store.commit('sidebarMinimize')
const toggleConfigurator = () => store.commit('toggleConfigurator')

// const closeMenu = () => {
//   setTimeout(() => {
//     showMenu.value = false
//   }, 100)
// }

const handleLogout = () => {
  LocalStorageSvc.clear()
  router.push('/home')
}
</script>

<template>
  <nav
    v-bind="$attrs"
    id="navbarBlur"
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
    data-scroll="true"
  >
    <div class="pr0 px-3 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />

      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
          <!-- <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              :placeholder="isRTL ? 'أكتب هنا...' : 'Type here...'"
            />
          </div> -->
        </div>
        <ul class="navbar-nav justify-content-end">
          <!-- <li class="nav-item d-flex align-items-center">
            <router-link
              :to="{ name: 'Signin' }"
              class="px-0 nav-link font-weight-bold text-white"
              target="_blank"
            >
              <i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-2'"></i>
              <span v-if="isRTL" class="d-sm-inline d-none">يسجل دخول</span>
              <span v-else class="d-sm-inline d-none">{{ fullName }}</span>
            </router-link>
          </li> -->
          <li class="nav-item mr30 ps-3 d-flex align-items-center">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="p-0 nav-link text-white"
              @click="minimizeSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
          <li class="nav-item d-flex align-items-center">
            <a class="p-0 nav-link text-white" @click="toggleConfigurator">
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li>
          <li class="nav-item dropdown d-flex align-items-center" :class="isRTL ? 'ps-2' : 'pe-2'">
            <el-dropdown placement="bottom" trigger="click">
              <el-button
                type="text"
                class="full-name-class font-weight-bold text-white d-sm-inline d-none"
              >
                <i class="cursor-pointer fa fa fa-user me-sm-2"></i>
                {{ fullName }}
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item class="p10" @click="handleLogout">
                    <i class="mr20 fa-solid fa-arrow-right-from-bracket me-3"></i>
                    Logout
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <!-- <a
              id="dropdownMenuButton"
              href="#"
              class="p-0 nav-link text-white"
              :class="[showMenu ? 'show' : '']"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="closeMenu"
            >
              <i class="cursor-pointer fa fa fa-user me-sm-2"></i>
              <span class="font-weight-bold text-white d-sm-inline d-none">
                {{ fullName }}
              </span>
            </a> -->
            <!-- <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton">
              <li @click="handleLogout">
                <a class="dropdown-item border-radius-md" href="javascript:;" @click="handleLogout">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i class="fa-solid fa-arrow-right-from-bracket me-3"></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Logout</span>
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul> -->
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style>
.full-name-class {
  padding: 0.5rem 0rem 1rem 2rem !important;
}
</style>
